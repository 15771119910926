<template>
  <div>
    <!-- Add Template Modal -->
    <CreateTemplateModal v-if="includeModal" @added="addedTemplate" ref="createTemplateModal" />

    <!-- Button to add if no templates exist -->
    <a
      class="btn btn-white btn-sm mb-0"
      href="javascript:;"
      v-if="!filteredTemplates.length"
      @click="addTemplate"
    >
      <i class="fe fe-plus"></i> Template
    </a>

    <!-- Templates Dropdown -->
    <b-dropdown
      :variant="selectedTemplate ? 'primary' : 'white'"
      size="sm"
      v-if="filteredTemplates.length"
      class="hide-caret"
    >
      <template slot="button-content">
        <span class="text-capitalize">
          <i class="fe fe-zap ml-1" style="position: relative; top: 1px"></i>
          {{ selectedTemplate ? selectedTemplate.title : '' | truncate(20) }}
        </span>
      </template>
      <b-dropdown-item
        v-for="template in filteredTemplates"
        :key="template._id"
        class="dropdown-item text-capitalize"
        @click="setTemplate(template)"
        >{{ template.title | truncate(50) }}</b-dropdown-item
      >
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item @click="addTemplate">Create Template</b-dropdown-item>
      <div v-if="selectedTemplate" class="dropdown-divider"></div>
      <b-dropdown-item class="text-warning" @click="reset()" v-if="selectedTemplate"
        >Reset</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
import CreateTemplateModal from '@/components/Modals/CreateTemplateModal'
import { createNamespacedHelpers } from 'vuex'
const TemplateModule = createNamespacedHelpers('template')
const CampaignModule = createNamespacedHelpers('campaign')
const ConversationModule = createNamespacedHelpers('conversation')

export default {
  name: 'TemplateDropdown',
  props: ['includeModal'],
  components: { CreateTemplateModal },
  data: () => ({
    loadModal: false,
    selectedTemplate: undefined,
  }),
  computed: {
    ...TemplateModule.mapGetters(['templates']),
    ...CampaignModule.mapGetters(['campaign']),
    ...ConversationModule.mapGetters(['activeConversation']),
    filteredTemplates() {
      let self = this
      return this.templates.filter((template) => {
        return self.activeConversation ? template.company == self.activeConversation.company : true
      })
    },
  },
  methods: {
    ...TemplateModule.mapActions(['queryTemplates']),
    addTemplate() {
      this.loadModal = true
      this.$root.$emit('add-template')
      if (this.includeModal) this.$refs.createTemplateModal.open()
    },
    setTemplate(template) {
      this.selectedTemplate = template
      this.$emit('selected', template)
    },
    reset() {
      this.selectedTemplate = undefined
      this.$emit('reset')
    },
    async addedTemplate(template) {
      this.queryTemplates()
      this.setTemplate(template)
    },
  },
  async mounted() {
    await this.queryTemplates()

    if (this.campaign.template) {
      this.setTemplate(
        this.filteredTemplates.find((template) => this.campaign.template === template._id)
      )
    }

    this.$root.$on('added-template', this.addedTemplate)
  },
  destroyed() {
    this.$root.$off('added-template', this.addedTemplate)
  },
}
</script>

<style scoped lang="scss">
a {
  text-transform: capitalize;
}
.dropdown-item {
  font-size: 14px;
}
</style>
