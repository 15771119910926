<template>
  <div>
    <button
      class="btn btn-sm bg-white border"
      title="Send Custom Question List"
      @click="sendList"
      :disabled="listAlreadySent || justSent"
    >
      <i class="fas fa-envelope-open-text"></i>
    </button>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import _ from 'lodash-es'

const TimelineModule = createNamespacedHelpers('timeline')
const CustomQuestionModule = createNamespacedHelpers('customQuestion')
const ConversationModule = createNamespacedHelpers('conversation')

export default {
  name: 'SendQuestionListButton',
  data: () => ({
    eventsCopy: [],
    justSent: false,
  }),
  computed: {
    ...TimelineModule.mapState(['events', 'customer', 'location']),
    ...ConversationModule.mapState(['activeConversation']),
    mostRecentSurveyEvent() {
      return this.eventsCopy.find((event) => event.type === 'left-survey')
    },
    listAlreadySent() {
      return this.mostRecentSurveyEvent && this.mostRecentSurveyEvent.survey.customQuestionList
        ? true
        : false
    },
  },
  methods: {
    ...CustomQuestionModule.mapActions(['sendCustomQuestionList']),
    ...TimelineModule.mapActions(['initializeTimeline']),
    async sendList() {
      try {
        await this.sendCustomQuestionList({ surveyId: this.mostRecentSurveyEvent.survey._id })
        await this.initializeTimeline({
          customer: this.customer,
          location: this.location,
          conversation: this.activeConversation,
        })
        this.justSent = true
      } catch (error) {
        this.$notify({
          type: 'error',
          title: `Failed to send Custom Question List for survey: ${this.mostRecentSurveyEvent.survey._id}`,
        })
      }
    },
  },
  mounted() {
    this.eventsCopy = _.cloneDeep(this.events)
  },
}
</script>

<style scoped>
.btn:hover {
  background: var(--light) !important;
}
.btn:focus {
  outline: none;
  box-shadow: none;
  background: white !important;
}
</style>
