<template>
  <b-modal
    ref="timelineModal"
    :id="elId"
    hide-footer
    hide-header
    @show="shown"
    @hide="hidden"
    body-class="p-0"
    style="padding-right: 0px; max-height: 95vh"
  >
    <div class="modal-card card" v-if="customer">
      <div
        class="card-header d-flex flex-column justify-content-center"
        style="align-items: inherit"
      >
        <div class="row align-items-center">
          <div class="col pl-0 ml-0">
            <h4 class="card-header-title text-capitalize">
              <button @click="close()" class="btn pl-0 pr-1 ml-0 arrow-button">
                <span class="fe fe-chevron-left"></span>
              </button>
              <div class="mr-2 d-inline-block">
                <span
                  v-if="customer"
                  class="btn-pointer"
                  v-b-tooltip.hover.bottom
                  :title="customer.phone | phone"
                  >{{ customer.name || 'Anonymous' }}</span
                >
              </div>
            </h4>
          </div>
          <div class="col-auto" v-if="activeConversation">
            <button
              @click="toggleResolve"
              class="btn btn-sm d-inline-block mr-2"
              :class="{
                'btn-primary': highlightResolve,
                'btn-white': !highlightResolve,
              }"
              id="resolveButton"
              v-b-tooltip="{
                title: activeConversation.resolved
                  ? 'You can reopen this to keep a close eye on it.'
                  : 'You\'re safe to mark this as resolved. If they reply, it will automatically re-open.',
                placement: 'top',
                trigger: 'hover',
                variant: 'primary',
              }"
            >
              {{ activeConversation.resolved ? 'Reopen' : 'Resolve' }}
            </button>
            <button
              class="btn btn-sm d-inline-block mr-2"
              @click="toggleBlock"
              :class="{
                'btn-white': !isCustomerBlocked,
                'btn-danger': isCustomerBlocked,
              }"
              v-b-tooltip="{
                title: `${
                  isCustomerBlocked ? 'Unblocks' : 'Blocks'
                } future messages from this customer.`,
                placement: 'top',
                trigger: 'hover',
                variant: 'primary',
              }"
            >
              <i class="fe fe-user-x"></i>
            </button>
            <button @click="close" type="button" class="close d-inline-block mt-1">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
        <div v-if="activeConversation" class="d-flex align-items-center" style="overflow: auto">
          <div v-for="tag in tags" :key="tag._id" class="badge badge-light mr-2">
            {{ tag.name }}
          </div>
        </div>
      </div>

      <div class="card-body" id="timelineBody" style="min-height: 55vh">
        <div class="row">
          <div class="col-12">
            <CustomerTimeline />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="container">
        <div class="row">
          <div class="col-12" v-if="customer && location">
            <MessageCompose
              ref="composer"
              :prepopulateWith="contactText"
              :customer="customer"
              :location="location"
              v-if="location.bandwidth"
              @sent="sendReply($event)"
              @typing="$root.$emit('bv::hide::tooltip')"
              @scrollBottom="scrollTo"
              @sentPoints="sentPoints"
            />
            <div v-if="!location.bandwidth" class="alert alert-light fade show mb-0" role="alert">
              <strong>Uh-oh!</strong>
              SMS capabilities are not enabled for your
              {{ location.friendlyName || location.city }} location.
              <a href="mailto:hello@ovationup.com">Contact us</a> to enable them.
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import CustomerTimeline from '@/components/Modules/Customer/CustomerTimeline/CustomerTimeline'
import CustomerService from '@/services/CustomerService'
import { createNamespacedHelpers } from 'vuex'
import { bootIntercom, shutdownIntercom } from '@/lib/analytics'
import MessageCompose from '@/components/Modules/Message/MessageCompose'
import { isMobile } from 'mobile-device-detect'
import { disablePageScroll, enablePageScroll, addLockableTarget } from 'scroll-lock'
const ConversationModule = createNamespacedHelpers('conversation')
const CompanyModule = createNamespacedHelpers('company')
const UserModule = createNamespacedHelpers('user')
const TimelineModule = createNamespacedHelpers('timeline')
const TagModule = createNamespacedHelpers('tag')

export default {
  name: 'TimelineModal',
  components: {
    CustomerTimeline,
    MessageCompose,
  },
  data: () => ({
    elId: 'timelineModal',
    highlightResolve: false,
    contactText: '',
    isCustomerBlocked: false,
  }),
  computed: {
    ...TagModule.mapGetters(['tagsMap']),
    ...UserModule.mapGetters(['onlineUser']),
    ...CompanyModule.mapGetters(['activeCompany']),
    ...ConversationModule.mapGetters(['activeConversation']),
    ...TimelineModule.mapGetters(['customer', 'location']),
    companyId() {
      return this.activeConversation ? this.activeConversation.company : this.activeCompany._id
    },
    tags() {
      const tagIds = (this.customer.tags || {})[this.companyId] || []
      const tags = tagIds.map((t) => this.tagsMap[t]).filter(Boolean)
      return tags
    },
  },
  methods: {
    ...UserModule.mapActions(['pushWSMessageHandler']),
    ...TimelineModule.mapActions(['initializeTimeline']),
    ...ConversationModule.mapActions(['resolve', 'reply', 'create', 'queryConversations']),
    ...ConversationModule.mapMutations(['setActiveConversation']),
    async onWSMessage(message) {
      const { type, detail } = message

      switch (type) {
        case 'refreshWinback':
          await this.initializeTimeline({
            customer: this.customer,
            location: this.location,
            conversation: this.activeConversation,
          })
          break
      }
    },
    async sendReply({ message, promoId }) {
      try {
        // If there is no conversation, find it or create it
        if (!this.activeConversation) {
          await this.create({
            locationId: this.location._id,
            customerId: this.customer._id,
          })
        }

        await this.reply({
          conversationId: this.activeConversation._id,
          companyId: this.activeConversation.company,
          locationId: this.location._id,
          customerId: this.customer._id,
          promoId,
          message,
        })

        // Refresh the data afterwards - should be converted to websocket update
        await this.initializeTimeline({
          customer: this.customer,
          location: this.location,
          conversation: this.activeConversation,
        })

        this.highlightResolve = true
        setTimeout(() => {
          this.$root.$emit('bv::show::tooltip', 'resolveButton')
        }, 2000)
      } catch (error) {
        console.log('error', error)
      }
    },
    async sentPoints() {
      // Refresh the data afterwards - should be converted to websocket update
      await this.initializeTimeline({
        customer: this.customer,
        location: this.location,
        conversation: this.activeConversation,
      })
    },
    async toggleBlock() {
      if (this.isCustomerBlocked) {
        await CustomerService.unblock({
          companyId: this.companyId,
          customerId: this.customer._id,
        })
      } else {
        await CustomerService.block({
          companyId: this.companyId,
          customerId: this.customer._id,
        })
      }
      this.isCustomerBlocked = !this.isCustomerBlocked
      this.queryConversations()
    },
    toggleResolve() {
      this.close()
      this.resolve({
        conversationId: this.activeConversation._id,
        userId: this.activeConversation._id,
      })
      this.resolved = !this.resolved
    },
    shown() {
      shutdownIntercom(this.$intercom)

      this.highlightResolve = false
      // Lock scrolling
      if (!isMobile) {
        addLockableTarget(document.querySelector('html'))
        disablePageScroll()
      }

      // Scroll to bottom
      setTimeout(() => {
        var objDiv = document.getElementById('timelineBody')
        objDiv.scrollTop = objDiv.scrollHeight
        if (this.$refs.composer) {
          this.$refs.composer.focusTextarea()
        }
      }, 0)

      // Check if customer is blocked
      this.isCustomerBlocked = false
      if (this.customer.blocked && this.customer.blocked.includes(this.companyId))
        this.isCustomerBlocked = true
    },
    hidden() {
      // Unlock scrolling when modal closes
      enablePageScroll()
    },
    close() {
      bootIntercom(this.$intercom)
      this.$refs.timelineModal.hide()
    },
    scrollTo() {
      document.getElementById(this.elId).scrollTo(0, document.body.scrollHeight)
    },
  },
  mounted() {
    this.pushWSMessageHandler({ key: 'winback', handler: this.onWSMessage })
  },
}
</script>

<style scoped lang="scss">
.arrow-button {
  background: transparent !important;

  span {
    top: -2px;
    position: relative;
    opacity: 0.6;
    font-size: 18px;
    &:hover {
      opacity: 1;
    }
  }
}
.btn-pointer {
  cursor: pointer;
}
</style>
