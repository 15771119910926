<template>
  <div class="message" v-if="event.order && event.integration">
    <div class="row mt-1 mb-1 message">
      <div class="col-auto mt-2">
        <Avatar :img="integrationDetails.img" size="xs" class="timeline-integration-avatar" />
      </div>
      <div class="col ml--2">
        <div class="text-left promo comment-body">
          <div v-show="orderNumber" class="order-number">{{ orderNumber }}</div>
          <div class="comment-text" role="alert">
            <span>
              Spent
              <strong>{{ orderTotal | currency }}</strong>
              on
              <span>{{ items }}</span>
              via {{ integrationDetails.name }}
            </span>
            <span class="badge badge-soft-primary ml-1 mb-1 mt-n1">Order</span>
            <div class="row">
              <div class="col-auto">
                <p class="comment-time">
                  {{ event.created | moment('LT') }},
                  {{ event.location.friendlyName || event.location.city }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { map } from 'lodash-es'
import Avatar from '@/components/MainContent/Avatar'
import { getIntegrationDetails, availableIntegrations } from '@/lib/modules/integration'
export default {
  name: 'OrderEvent',
  components: {
    Avatar,
  },
  computed: {
    integrationDetails() {
      return getIntegrationDetails(this.event.integration.provider)
    },
    orderNumber() {
      const { posOrderNumber } = this.event.order
      return posOrderNumber && posOrderNumber.length < 20 ? `ORDER #${posOrderNumber}` : ''
    },
    items() {
      const items = map(this.event.order.items, 'name')
      if (items.length === 1) return items[0]
      return items.slice(0, items.length - 1).join(', ') + ', and ' + items.slice(-1)
    },
    orderTotal() {
      const { order, integration } = this.event
      const total = '$numberDecimal' in order.total ? order.total.$numberDecimal : order.total

      return integration.provider === availableIntegrations.skipTheDishes().provider
        ? total / 100
        : total
    },
  },
  props: ['event'],
}
</script>

<style lang="scss" scoped>
.order-number {
  font-weight: bold;
}
</style>
