<template>
  <div>
    <div style="height: 25px">
      <div v-if="!events || isLoading" class="is-loading is-loading-primary mt-4" />
    </div>
    <div class="timeline" v-if="events" id="timelineList">
      <div v-for="eventGroup of eventList" :key="eventGroup.timestamp">
        <!-- timestamp for grouped events -->
        <div v-if="eventGroup" class="text-center mb-2 mt-1">
          <span style="font-size: 10px">
            {{ eventGroup.timestamp }}
          </span>
        </div>
        <!-- end timestamp -->

        <div
          v-for="event of eventGroup.events"
          :key="event._id"
          class="row"
          :class="{
            'justify-content-end':
              event.user ||
              event.type === 'sent-campaign' ||
              event.type === 'sent-custom-question-list',
          }"
        >
          <OrderEvent v-if="event.type === 'order'" :event="event" />
          <CampaignEvent v-if="event.type === 'sent-campaign'" :event="event" />
          <CheckinEvent v-else-if="event.type === 'checkin' && event.location" :event="event" />
          <LoyaltyEvent
            v-else-if="event.type === 'spent-points'"
            :event="event"
            :company="activeCompany"
          />
          <LoyaltyEvent
            v-else-if="event.type === 'earned-points'"
            :company="activeCompany"
            :event="event"
          />
          <FeedbackEvent v-else-if="event.type === 'left-feedback'" :event="event" />
          <LeftReviewEvent v-else-if="event.type === 'left-review'" :event="event" />
          <MessageEvent v-else-if="event.type === 'outgoing-message'" :event="event" />
          <MessageEvent v-else-if="event.type === 'incoming-message'" :event="event" />
          <PromoRedeemEvent v-else-if="event.type === 'redeem-promo'" :event="event" />
          <PromoSentEvent v-else-if="event.type === 'sent-promo'" :event="event" />
          <SurveyEvent v-else-if="event.type === 'left-survey'" :event="event" />
          <CompletedCustomQuestionListEvent
            v-else-if="event.type === 'completed-custom-question-list'"
            :event="event"
          />
          <SentCustomQuestionListEvent
            v-else-if="event.type === 'sent-custom-question-list'"
            :event="event"
          />
          <span v-else>
            <!-- <pre> </pre>  -->
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { throttle } from 'lodash-es'
import CampaignEvent from './CustomerTimelineEvent/CampaignEvent'
import CheckinEvent from './CustomerTimelineEvent/CheckinEvent'
import OrderEvent from './CustomerTimelineEvent/OrderEvent'
import SurveyEvent from './CustomerTimelineEvent/SurveyEvent'
import FeedbackEvent from './CustomerTimelineEvent/FeedbackEvent'
import LeftReviewEvent from './CustomerTimelineEvent/LeftReviewEvent'
import MessageEvent from './CustomerTimelineEvent/MessageEvent'
import PromoRedeemEvent from './CustomerTimelineEvent/PromoRedeemEvent'
import PromoSentEvent from './CustomerTimelineEvent/PromoSentEvent'
import LoyaltyEvent from './CustomerTimelineEvent/LoyaltyEvent'
import CompletedCustomQuestionListEvent from './CustomerTimelineEvent/CompletedCustomQuestionListEvent'
import SentCustomQuestionListEvent from './CustomerTimelineEvent/SentCustomQuestionListEvent'

import { createNamespacedHelpers } from 'vuex'
const CompanyModule = createNamespacedHelpers('company')
const TimelineModule = createNamespacedHelpers('timeline')
const ConversationModule = createNamespacedHelpers('conversation')

export default {
  name: 'CustomerTimeline',
  data: () => ({
    loading: true,
    eventList: [],
    showIncrement: 5,
    showLimit: 5,
    isLoading: false,
  }),
  components: {
    CampaignEvent,
    CheckinEvent,
    FeedbackEvent,
    LeftReviewEvent,
    MessageEvent,
    PromoRedeemEvent,
    PromoSentEvent,
    SurveyEvent,
    OrderEvent,
    LoyaltyEvent,
    CompletedCustomQuestionListEvent,
    SentCustomQuestionListEvent,
  },
  computed: {
    ...TimelineModule.mapState(['events']),
    ...TimelineModule.mapGetters(['getLimit']),
    ...CompanyModule.mapState(['activeCompany']),
    ...ConversationModule.mapGetters(['activeConversation']),
  },
  watch: {
    events: function () {
      this.eventList = this.sortEventsByDate(this.filteredEvents(this.events))
      // Scroll to bottom
      if (this.events.length <= this.getLimit) {
        setTimeout(() => {
          var objDiv = document.getElementById('timelineBody')
          objDiv.scrollTop = objDiv.scrollHeight
        }, 0)
      }
    },
  },

  mounted() {
    this.eventList = this.sortEventsByDate(this.filteredEvents(this.events))
    this.scroll()
  },

  methods: {
    ...TimelineModule.mapActions(['fetchMoreTimelineEvents']),
    scroll() {
      const getMoreEvents = throttle(
        async () => {
          this.isLoading = true
          let oldOffsetHeight = $('#timelineList')[0].offsetHeight
          await this.fetchMoreTimelineEvents({ conversation: this.activeConversation })
          $('#timelineBody')[0].scrollTop = $('#timelineList')[0].offsetHeight - oldOffsetHeight
          this.isLoading = false
        },
        1000,
        { trailing: false }
      )
      $('#timelineBody').scroll(() => {
        if ($('#timelineBody').scrollTop() == 0 && this.events.length >= this.getLimit) {
          getMoreEvents()
        }
      })
    },
    sortEventsByDate(events) {
      const sortedEvents = []
      events
        .sort((eventA, eventB) => this.$moment(eventA.created) - this.$moment(eventB.created))
        .forEach((event) => {
          // check if the array is empty
          if (sortedEvents.length == 0) {
            const obj = {}
            Object.assign(obj, {
              timestamp: this.dateMapper(event.created),
              events: [event],
            })
            sortedEvents.push(obj)
          } else {
            // check if the index item exists in the array
            const index = sortedEvents.findIndex(
              (e) => e.timestamp === this.dateMapper(event.created)
            )
            if (index > -1) {
              let item = sortedEvents[index]
              item = { ...item, events: [...item.events, event] }
              // update the item at index
              sortedEvents[index] = item
            } else {
              const obj = {}
              Object.assign(obj, {
                timestamp: this.dateMapper(event.created),
                events: [event],
              })
              // push the new object to array
              sortedEvents.push(obj)
            }
          }
        })

      return this.SortRatingBeforeFeedback(sortedEvents)
    },

    dateMapper(date) {
      const today = this.$moment()
      const yesterday = today.clone().subtract(1, 'day')

      if (this.$moment(date).isSame(today, 'day')) {
        return 'Today'
      } else if (this.$moment(date).isSame(yesterday, 'day')) {
        return 'Yesterday'
      } else {
        return `${this.$moment(date).format('ll')}`
      }
    },

    swap(a, b, arr) {
      arr[a] = arr.splice(b, 1, arr[a])[0]
      return arr
    },

    SortRatingBeforeFeedback(events) {
      events.map((timestamp) => {
        timestamp.events.map((event, index, arr) => {
          const next = arr.length > index + 1 ? arr[index + 1] : arr[index]

          if (event.type === 'left-feedback' && next.type === 'left-survey') {
            const nextIndex = arr.length > index + 1 ? index + 1 : index
            // swapping the events around
            return this.swap(index, nextIndex, arr)
          }
          return arr
        })
      })
      return events
    },

    filteredEvents(events) {
      const sortedEvents = []
      // displayed customer event types
      const eventsTypes = [
        'sent-campaign',
        'order',
        'spent-points',
        'left-review',
        'left-feedback',
        'incoming-message',
        'outgoing-message',
        'redeem-promo',
        'left-survey',
        'sent-promo',
        'earned-points',
        'checkin',
        'completed-custom-question-list',
        'sent-custom-question-list',
      ]

      // filter all event, return only the ones matching eventType array
      eventsTypes.forEach((type) => {
        events.forEach((event) => {
          if (event.type.trim() == type.trim()) {
            sortedEvents.push(event)
          }
        })
      })
      return sortedEvents
    },
  },
}
</script>
