<template>
  <Message
    :content="event.survey.feedback"
    :user="event.customer || {}"
    :timestamp="event.created"
    label="Feedback"
  ></Message>
</template>

<script>
import Message from '@/components/Modules/Message/Message'
export default {
  name: 'FeedbackEvent',
  props: ['event'],
  components: {
    Message,
  },
}
</script>

<style scoped lang="scss"></style>
