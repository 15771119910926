<template>
  <div class="message">
    <div class="row mt-1 mb-1 message">
      <div class="col-auto mt-1">
        <a
          href="javascript:;"
          v-if="!event.platform"
          class="btn btn-sm btn-warning btn-rounded-circle"
          ><i class="fe fe-star icon-top-adjust text-white"></i
        ></a>
        <Avatar
          class="mt-1"
          v-if="event.platform"
          :img="'/theme/img/platforms/' + event.platform.source + '.svg'"
          size="xs"
          status=""
        />
      </div>
      <div class="col ml--3">
        <div class="text-left promo comment-body">
          <div class="comment-text" role="alert">
            <span
              >Clicked to leave a<span v-if="event.platform"
                ><span class="text-capitalize"> {{ event.platform.source }}</span></span
              >
              review.</span
            >
            <div class="row">
              <div class="col-auto">
                <p class="comment-time">
                  {{ event.created | moment('LT') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/MainContent/Avatar'
export default {
  name: 'LeftReviewEvent',
  props: ['event'],
  components: { Avatar },
}
</script>
