<template>
  <div class="message">
    <div class="row mt-1 mb-1 message">
      <div class="col-auto mt-0">
        <a href="javascript:;" class="btn btn-sm btn-info btn-rounded-circle mt-1"
          ><i class="fe fe-map-pin icon-top-adjust"></i
        ></a>
      </div>
      <div class="col ml--2">
        <div class="text-left promo comment-body">
          <div class="comment-text" role="alert">
            <span
              >Visited your
              {{ event.location.friendlyName || event.location.city }}
              location.</span
            >
            <span class="badge badge-soft-primary ml-1 mb-1 mt-n1">Checkin</span>
            <div class="row">
              <div class="col-auto">
                <p class="comment-time">
                  {{ event.created | moment('LT') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckinEvent',
  props: ['event'],
}
</script>
