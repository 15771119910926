<template>
  <div class="message">
    <div class="row mt-1 message">
      <div class="col-auto mt-0">
        <a href="javascript:;" class="btn btn-sm btn-success btn-rounded-circle mt-1"
          ><i class="fe fe-dollar-sign icon-inner-adjust"></i
        ></a>
      </div>
      <div class="col ml--2">
        <div class="text-left promo comment-body">
          <div class="comment-text" role="alert">
            <span class="text-capitalize">Redeemed {{ event.promo.title }}</span>
            <span class="badge badge-soft-primary ml-1 mb-1 mt-n1">Offer</span>
            <div class="text-muted" v-if="uniqueCode">Code: {{ uniqueCode }}</div>
            <div class="row mt-2 mb--2">
              <div class="col-auto">
                <p class="comment-time mt-1">
                  {{ event.created | moment('LT') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const TimelineModule = createNamespacedHelpers('timeline')

export default {
  name: 'PromoRedeemEvent',
  props: ['event'],
  data: () => ({
    uniqueCode: '',
  }),
  async created() {
    if (this.event.promo.hasUniqueInStoreCodes || this.event.promo.hasUniqueOffPremiseCodes) {
      try {
        const response = await this.getUsedUniqueCode({
          customerId: this.event.customer._id,
          promoId: this.event.promo._id,
          redemptionTimestamp: this.event.created,
        })
        if (response.data.exists) {
          this.uniqueCode = response.data.promoCode
        }
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: 'Failed to retrieve used Winback promo codes',
        })
      }
    }
  },
  methods: {
    ...TimelineModule.mapActions(['getUsedUniqueCode']),
  },
}
</script>
