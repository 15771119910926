<template>
  <div class="message">
    <div class="row mt-1 mb-1 message">
      <div class="col-auto mt-0 emoti">
        {{ getRating(event.survey.rating).emoti }}
      </div>
      <div class="col ml--2">
        <div class="text-left promo comment-body">
          <div class="comment-text" role="alert">
            <span
              >Rated their experience as
              <strong>{{ getRating(event.survey.rating).title }}.</strong></span
            >
            <div class="row mt-2 mb--2">
              <div class="col-auto">
                <p class="comment-time">
                  {{ event.created | moment('LT') }},
                  {{ event.location.friendlyName || event.location.city }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRating } from '@/lib/rating'

export default {
  name: 'SurveyEvent',
  props: ['event'],
  methods: {
    getRating,
  },
}
</script>

<style lang="scss">
.emoti {
  font-size: 26px;
}
</style>
