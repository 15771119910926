<template>
  <div>
    <!-- Add Promo Modal -->
    <CreatePromoModal ref="createPromoModal" @added="addedPromo" />
    <!-- Add Template Modal -->
    <CreateTemplateModal ref="createTemplateModal" @added="addedTemplate" />
    <!-- Timeline Modal -->
    <TimelineModal ref="timelineModal" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TimelineModal from '@/components/Modals/TimelineModal'
import CreatePromoModal from '@/components/Modals/CreatePromoModal'
import CreateTemplateModal from '@/components/Modals/CreateTemplateModal'
const PromoModule = createNamespacedHelpers('promo')
const TemplateModule = createNamespacedHelpers('template')

export default {
  name: 'CustomerTimelineModalGroup',
  components: {
    TimelineModal,
    CreatePromoModal,
    CreateTemplateModal,
  },
  methods: {
    ...PromoModule.mapActions(['queryPromos']),
    ...TemplateModule.mapActions(['queryTemplates']),
    openAddTemplateModal() {
      this.$root.$emit('bv::show::modal', 'createTemplateModal')
    },
    addedTemplate(template) {
      this.$root.$emit('bv::show::modal', 'timelineModal')
      this.$root.$emit('added-template', template)
    },
    openAddPromoModal() {
      this.$root.$emit('bv::show::modal', 'createPromoModal')
    },
    addedPromo(promo) {
      this.$root.$emit('bv::show::modal', 'timelineModal')
      this.$root.$emit('added-promo', promo)
    },
  },
  async mounted() {
    this.$root.$on('add-promo', this.openAddPromoModal)
    this.$root.$on('add-template', this.openAddTemplateModal)
    this.queryPromos()
    this.queryTemplates()
  },
}
</script>
