<template>
  <div>
    <div class="row">
      <div class="col-auto" v-if="showAvatar">
        <!-- Avatar -->
        <Avatar
          :img="onlineUser.avatar"
          :first-name="onlineUser.firstName"
          :last-name="onlineUser.lastName"
          size="sm"
          status="online"
        />
      </div>
      <div class="col ml--2">
        <!-- Input -->
        <div>
          <label class="sr-only">Leave a comment...</label>
          <div class="input-group">
            <textarea-autosize
              v-model="message"
              v-on:focus.native="didFocus()"
              v-on:blur.native="didBlur()"
              @input="typing()"
              ref="messageInput"
              class="form-control message-input"
              placeholder="Message"
              rows="1"
            ></textarea-autosize>
            <div class="input-group-append">
              <button
                class="btn btn-primary btn-sm float-right"
                :disabled="message.length < 3"
                @click="sendMessage()"
              >
                <span class="fe fe-send"></span>
                Send
              </button>
            </div>
          </div>
        </div>

        <div class="row align-items-right mt-3">
          <div class="col">
            <div>
              <PromoDropdown
                class="d-inline-block mr-2 mb-2"
                ref="promoDropdown"
                v-on:add="newPromo"
                v-on:selected="addPromo"
                v-on:reset="resetPromo"
                :includeUniquePromos="true"
                source="winback"
              />
              <TemplateDropdown
                class="d-inline-block mr-2 mb-2"
                ref="templateDropdown"
                v-on:add="newTemplate"
                v-on:selected="addTemplate"
                v-on:reset="resetTemplate"
              />
              <LoyaltyDropdown
                v-if="loyaltyEnabled"
                :customer="customer"
                :company="companyId"
                :location="location"
                class="d-inline-block mr-2 mb-2"
                ref="loyaltyDropdown"
                @sentPoints="sentPoints"
              />
              <SendQuestionListButton class="d-inline-block mr-2 mb-2" v-if="showSendListButton" />
            </div>
          </div>
          <div class="col-auto">
            <p class="text-muted small mt-1 d-none d-sm-inline-block">
              {{ message.length }} characters
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import TemplateService from '@/services/TemplateService'
import { createNamespacedHelpers } from 'vuex'
const UserModule = createNamespacedHelpers('user')
const CompanyModule = createNamespacedHelpers('company')
const ConversationModule = createNamespacedHelpers('conversation')
import PromoDropdown from '@/components/Modules/Promo/PromoDropdown'
import TemplateDropdown from '@/components/Modules/Template/TemplateDropdown'
import LoyaltyDropdown from '@/components/Modules/Loyalty/LoyaltyDropdown'
import SendQuestionListButton from '@/components/Modules/CustomQuestions/SendQuestionListButton'
import Avatar from '@/components/MainContent/Avatar'

export default {
  name: 'MessageCompose',
  props: ['prepopulateWith', 'customer', 'location'],
  components: {
    Avatar,
    PromoDropdown,
    TemplateDropdown,
    LoyaltyDropdown,
    SendQuestionListButton,
  },
  data: () => ({
    message: '',
    showAvatar: true,
    promoId: undefined,
    emitScrollLength: 100,
    emitScrollStep: 15,
  }),
  computed: {
    ...ConversationModule.mapGetters(['activeConversation']),
    ...UserModule.mapGetters(['onlineUser']),
    ...CompanyModule.mapGetters(['loyaltyEnabled', 'activeCompany']),
    companyId() {
      return this.activeConversation ? this.activeConversation.company : this.activeCompany._id
    },
    showSendListButton() {
      // TODO change to feature flag. For now only show for Zack's Shack and Ovation Dev
      const companyShowList = [
        '5c17bb7fc1a27231b61e71d6',
        '5d02afeee2e45649bb4b56fb',
        '5d31ed243e1a6d3b9f8c6387',
      ]
      if (this.activeConversation && companyShowList.includes(this.activeConversation.company)) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    addPromo(promo) {
      this.promoId = promo._id
    },
    async addTemplate(template) {
      const response = await TemplateService.interpolate({
        companyId: this.companyId,
        userId: this.onlineUser._id,
        locationId: this.location._id,
        customerId: this.customer._id,
        input: template.body,
      })
      this.message = response.body.output
    },
    newPromo() {
      this.$emit('newPromo')
    },
    newTemplate() {
      this.$emit('newTemplate')
    },
    sentPoints() {
      this.$emit('sentPoints')
    },
    resetPromo() {
      this.promoId = undefined
    },
    resetTemplate() {
      this.message = ''
    },
    async sendMessage() {
      this.$emit('sent', { message: this.message, promoId: this.promoId })
      this.message = ''
      this.$refs.promoDropdown.reset()
      this.$refs.templateDropdown.reset()
    },
    typing() {
      this.$emit('typing')
    },
    didFocus() {
      if (isMobile) this.showAvatar = false
    },
    didBlur() {
      if (isMobile) this.showAvatar = true
    },
    focusTextarea() {
      const context = this
      if (this.prepopulateWith) {
        this.message = this.prepopulateWith
      } else {
        this.message = ''
      }
      if (!isMobile) {
        let focusInterval = setInterval(() => {
          if (context.$refs.messageInput) {
            context.$refs.messageInput.$el.focus()
            clearInterval(focusInterval)
          }
        }, 100)
      }
    },
  },
  mounted() {
    this.$emit('scrollBottom')
  },
  watch: {
    message: function (val) {
      if (val.length > this.emitScrollLength) {
        this.$emit('scrollBottom')
        this.emitScrollLength += this.emitScrollStep
      }
    },
    prepopulateWith: function (val) {
      this.message = val
    },
  },
}
</script>

<style scoped lang="scss">
.message {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}
.message-input {
  min-height: 40px;
}
</style>
