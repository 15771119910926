<template>
  <div class="message" v-if="event.user">
    <div class="row mt-1 mb-1 message internal">
      <div class="col" :class="{ 'mr--2': event.user.role, 'ml--2': !event.user.role }">
        <div class="comment-body">
          <p class="comment-text text-capitalize">
            {{ event.promo.title }}
            <span class="badge badge-soft-primary ml-1 mb-1 mt-n1">Offer</span>
          </p>
          <div class="row">
            <div class="col-auto">
              <p class="comment-time">
                {{ event.created | moment('LT') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto mt-2">
        <Avatar
          v-if="event.user.role"
          :img="event.user.avatar"
          :first-name="event.user.firstName"
          :last-name="event.user.lastName"
          :name="event.user.name"
          size="xs"
          status=""
        />
        <a
          href="javascript:;"
          v-if="!event.user || !event.user.role"
          class="btn btn-sm btn-rounded-circle btn-primary"
          ><i class="fe fe-tag icon-inner-adjust"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/MainContent/Avatar'
export default {
  name: 'PromoSentEvent',
  props: ['event'],
  components: {
    Avatar,
  },
}
</script>
