<template>
  <div>
    <Message
      :content="event.message.body"
      :user="event.user || event.customer || {}"
      :timestamp="event.created"
      :media="event.message.media"
    ></Message>
  </div>
</template>

<script>
import Message from '@/components/Modules/Message/Message'
export default {
  name: 'MessageEvent',
  props: ['event'],
  components: {
    Message,
  },
}
</script>
